
import { Component, Vue } from "vue-property-decorator";
import BaseIcon from "@/components/UI/BaseIcon.vue";

@Component({
  components: { BaseIcon },
})
export default class Welcome extends Vue {
  get logoType(): string {
    if (window.innerWidth < 620) return "_mobile";
    return "";
  }

  openMap(): void {
    window.open("https://maps.app.goo.gl/uBQG2nsXznfY2e8E9?g_st=ic");
  }
}
