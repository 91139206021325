
import { Component, Vue } from "vue-property-decorator";
import BaseIcon from "@/components/UI/BaseIcon.vue";

@Component({
  components: { BaseIcon },
})
export default class Podcasts extends Vue {
  currentSlide = 0;

  podcasts = [
    {
      place: "Brooklyn",
      title: "Talking about technology and its impact on the future",
      date: "Oct 7th, 2022 | 35 mins",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit massa viverra molestie sem diam pretium ultrices sed. Lacinia proin blandit nunc id etiam facilisi nibh arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      img: "podcast1",
    },
    {
      place: "Simons",
      title: "Talking about Mao",
      date: "Dec 26th, 1893 | 1 min",
      description:
        "Mao was the son of a prosperous peasant in Shaoshan, Hunan. He supported Chinese nationalism and had an anti-imperialist outlook early in his life, and was particularly influenced by the events of the Xinhai Revolution of 1911 and May Fourth Movement of 1919. He later adopted Marxism–Leninism while working at Peking University as a librarian and became a founding member of the Chinese Communist Party (CCP), leading the Autumn Harvest Uprising in 1927.",
      img: "podcast2",
    },
    {
      place: "Brooklyn",
      title: "Talking about technology and its impact on the future",
      date: "Oct 7th, 2022 | 35 mins",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit massa viverra molestie sem diam pretium ultrices sed. Lacinia proin blandit nunc id etiam facilisi nibh arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      img: "podcast1",
    },
    {
      place: "Simons",
      title: "Talking about Mao",
      date: "Dec 26th, 1893 | 1 min",
      description:
        "Mao was the son of a prosperous peasant in Shaoshan, Hunan. He supported Chinese nationalism and had an anti-imperialist outlook early in his life, and was particularly influenced by the events of the Xinhai Revolution of 1911 and May Fourth Movement of 1919. He later adopted Marxism–Leninism while working at Peking University as a librarian and became a founding member of the Chinese Communist Party (CCP), leading the Autumn Harvest Uprising in 1927.",
      img: "podcast2",
    },
  ];

  get slidesPerPage(): number {
    if (window.innerWidth > 620) return 2;
    return 1;
  }

  prevSlide(): void {
    if (this.currentSlide !== 0) this.currentSlide -= 1;
  }

  nextSlide(): void {
    if (window.innerWidth > 620) {
      if (this.currentSlide !== this.podcasts.length / 2 - 1)
        this.currentSlide += 1;
    } else if (this.currentSlide !== this.podcasts.length - 1)
      this.currentSlide += 1;
  }

  changeSlide(index: number): void {
    this.currentSlide = index;
  }
}
