
import { Component, Vue } from "vue-property-decorator";
import SpeakerCard from "@/components/speaker/SpeakerCard.vue";
import BaseButton from "@/components/UI/BaseButton.vue";

@Component({
  components: { SpeakerCard, BaseButton },
})
export default class Speakers extends Vue {
  isFullSpeakerList = false;

  speakerList = [
    {
      name: "Manuela Sedvartaite",
      post: "INNOVATION, PRODUCT MANAGER, BANCO SANTANDER",
      company: "PAGONXT",
      linkedIn: "https://www.linkedin.com/in/manuela-sedvartaite",
      icon: "135",
    },
    {
      name: "William <br/> Shaw",
      post: "Sr. Account Executive",
      company: "Digital Transformation",
      linkedIn: "https://www.linkedin.com/in/willshawison/",
      icon: "134",
    },
    {
      name: "David <br/> Lara",
      post: "Partner",
      company: "Ichor Strategies",
      linkedIn: "https://www.linkedin.com/in/david-l-10892b2/",
      icon: "133",
    },
    {
      name: "Jason <br/> Mikula",
      post: "Managing Director",
      company: "312 Global Strategies BV",
      linkedIn: "https://www.linkedin.com/in/jasonmikula/",
      icon: "132",
    },
    {
      name: "Rich <br/> Radice",
      post: "Chief Executive Officer",
      company: "Moven",
      linkedIn: "https://www.linkedin.com/in/rich-radice-cpa-10145b2/",
      icon: "131",
    },
    {
      name: "Joel <br/> Curry",
      post: "Investor",
      company: " executive consultant",
      linkedIn: "https://www.linkedin.com/in/joel-curry-a06a1a6/",
      icon: "130",
    },
    {
      name: "Rutger van Faassen",
      post: "Head of New Markets & Industry Ecosystems",
      company: "Curinos",
      linkedIn: "https://www.linkedin.com/in/informationbanker/",
      icon: "129",
    },
    {
      name: "Caton <br/> Hanson",
      post: "Entrepreneur In Residence",
      company: "Lendio",
      linkedIn: "https://www.linkedin.com/in/catonhanson/",
      icon: "128",
    },
    {
      name: "Jason <br/> Henrichs",
      post: "CEO",
      company: "ALLOY LABS ALLIANCE",
      linkedIn: "https://www.linkedin.com/in/jasonhenrichs/",
      icon: "127",
    },
    {
      name: "Rish <br/> Sen Gupta",
      post: "Senior Director, Underwriting Insights",
      company: "Visa",
      linkedIn: "https://www.linkedin.com/in/rishsengupta/",
      icon: "126",
    },
    {
      name: "Brittany <br/> Stephen",
      post: "Senior Manager, Product Management",
      company: "Visa",
      linkedIn: "https://www.linkedin.com/in/brittany-stephen-3371426a/",
      icon: "125",
    },
    {
      name: "Gregory <br/> Hall",
      post: "Managing Partner",
      company: "Verizon",
      linkedIn: "https://www.linkedin.com/in/gregoryhall/",
      icon: "124",
    },
    {
      name: "David <br/> M. Dean",
      post: "CTO",
      company: "Strategic Risk Associates",
      linkedIn: "https://www.linkedin.com/in/david-m-dean-81a51a9/",
      icon: "123",
    },
    {
      name: "Krishnan Iyengar",
      post: "Group VP Strategic Initiatives",
      company: "Oracle",
      linkedIn: "https://www.linkedin.com/in/krishnan-iyengar-b53a283/",
      icon: "122",
    },
    {
      name: "Anders <br/> Olofsson",
      post: "Head of Payments",
      company: "Finastra",
      linkedIn: "https://www.linkedin.com/in/andersolofsson/",
      icon: "121",
    },
    {
      name: "Andrew <br/> Ballard",
      post: "SVP Business Development",
      company: "Lowers Risk Group",
      linkedIn: "https://www.linkedin.com/in/andrew-ballard-2b06523/",
      icon: "120",
    },
    {
      name: "Aloke <br/> Agarwal",
      post: "Director, Cloud Innovation & Engineering",
      company: "PwC",
      linkedIn: "https://www.linkedin.com/in/alokeagarwal/",
      icon: "119",
    },
    {
      name: "Michael <br/> Grove",
      post: "AVP, Director",
      company: "Bank of the Rockies",
      linkedIn: "https://www.linkedin.com/in/michael-grove-38315a157/",
      icon: "118",
    },
    {
      name: "Raj <br/> Sampoornam",
      post: "CIO",
      company: "Byline Bank",
      linkedIn: "https://www.linkedin.com/in/rajsampoornam/",
      icon: "117",
    },
    {
      name: "John <br/> Piazza",
      post: "VP - Director, Product & Strategy",
      company: "Fifth Third Bank",
      linkedIn: "https://www.linkedin.com/in/piazzajohn/",
      icon: "116",
    },
    {
      name: "Ashish <br/> Agarwal",
      post: "VP; Head of Data",
      company: "LendingTree",
      linkedIn: "https://www.linkedin.com/in/toashishagarwal/",
      icon: "115",
    },
    {
      name: "Glenn <br/> Murray",
      post: "VP - Strategic Growth",
      company: "Sutton Payments Division",
      linkedIn: "https://www.linkedin.com/in/gmurray3/",
      icon: "110",
    },
    {
      name: "Alecsa <br/> Geaga",
      post: "Business Analyst",
      company: "Sunwest Bank",
      linkedIn: "https://www.linkedin.com/in/alecsa-geaga/",
      icon: "108",
    },
    {
      name: "Paddy <br/> Gonzales",
      post: "VP, Application Delivery Manager",
      company: "Sunwest Bank",
      linkedIn: "https://www.linkedin.com/in/padraighgonzalez/",
      icon: "107",
    },
    {
      name: "Scott <br/> Hurlbert",
      post: "SVP, Digital Products",
      company: "People's United Bank, N.A.",
      linkedIn: "https://www.linkedin.com/in/hurlbert/",
      icon: "105",
    },
    {
      name: "Pamela <br/> Cytron",
      post: "Advisory Board Member",
      company: "First Rate Ventures",
      linkedIn: "https://www.linkedin.com/in/pamelacytron/",
      icon: "103",
    },
    {
      name: "Chris <br/> McDonnell",
      post: "Head of Banking Transformation",
      company: "Coalition Greenwich",
      linkedIn: "https://www.linkedin.com/in/chrisbmcdonnell/",
      icon: "102",
    },
    {
      name: "Senthil <br/> Ravindran",
      post: "Partner",
      company: "ConceptVines",
      linkedIn: "https://www.linkedin.com/in/senthil-ravindran/",
      icon: "101",
    },
    {
      name: "Jasmine <br/> Samra",
      post: "Counsel",
      company: "Gowling WLG",
      linkedIn: "https://www.linkedin.com/in/jasmine-samra-02aa2254/",
      icon: "100",
    },
    {
      name: "Tristan <br/> Thompson",
      post: "Payments Strategy Director",
      company: "Arvest Bank",
      linkedIn: "https://www.linkedin.com/in/tristanthompson/",
      icon: "99",
    },
    {
      name: "Nathan <br/> Sapsin",
      post: "CEO",
      company: "IQ BANKER",
      linkedIn: "https://www.linkedin.com/in/nathan-s-7350796/",
      icon: "98",
    },
    {
      name: "Vijay <br/> Balachandran",
      post: "Senior Product Manager",
      company: "Bench Accounting",
      linkedIn: "https://www.linkedin.com/in/vijayamohan/",
      icon: "97",
    },
    {
      name: "Blake <br/> Turner",
      post: "VP of Technology",
      company: "Bench Accounting",
      linkedIn: "https://www.linkedin.com/in/blake-turner-a318a659/",
      icon: "96",
    },
    {
      name: "Niloy <br/> Sengupta",
      post: "Director",
      company: "Guidehouse",
      linkedIn: "https://www.linkedin.com/in/niloysengupta/",
      icon: "95",
    },
    {
      name: "Jason <br/> Hendry",
      post: "Co-Founder",
      company: "FDX",
      linkedIn: "https://www.linkedin.com/in/jason-hendry-b0a65b7/",
      icon: "94",
    },
    {
      name: "Matt <br/> March",
      post: "EVP / CIO",
      company: "Sunwest Bank",
      linkedIn: "https://www.linkedin.com/in/mmarch/",
      icon: "93",
    },
    {
      name: "Butch <br/> Langlois",
      post: "Founding Member",
      company: "Pavilion",
      linkedIn: "https://www.linkedin.com/in/butchlanglois/",
      icon: "89",
    },
    {
      name: "Ben <br/> Martin",
      post: "Finance Director",
      company: "Fiserv",
      linkedIn: "https://www.linkedin.com/in/benjaminmmartin/",
      icon: "88",
    },
    {
      name: "Adwait <br/> Joshi",
      post: "Chief Seer",
      company: "DataSeers",
      linkedIn: "https://www.linkedin.com/in/aajoshi/",
      icon: "87",
    },
    {
      name: "Hannes <br/> Liebe",
      post: "CCO",
      company: "Finastra",
      linkedIn: "https://www.linkedin.com/in/hannesliebe/",
      icon: "43",
    },
    {
      name: "Khurram <br/> Khalil",
      post: "COO",
      company: "CAARY",
      linkedIn: "https://www.linkedin.com/in/khurramkhalil/",
      icon: "86",
    },
    {
      name: " Michael <br/> Bryan",
      post: "SEVP, СIO",
      company: "Veritex Community Bank",
      linkedIn: "https://www.linkedin.com/in/michaelbryantechnologist/",
      icon: "85",
    },
    {
      name: "Don <br/> Cardinal",
      post: "Managing Director",
      company: "Financial Data Exchange",
      linkedIn: "https://www.linkedin.com/in/doncardinal/",
      icon: "83",
    },
    {
      name: "Steven <br/> Walchek",
      post: "EVP, CIO",
      company: "FIS",
      linkedIn: "https://www.linkedin.com/in/swalchek/",
      icon: "82",
    },
    {
      name: "Jason <br/> Hagan",
      post: "SVP",
      company: "Sunrise Banks",
      linkedIn: "https://www.linkedin.com/in/jasonhagan/",
      icon: "81",
    },
    {
      name: "Nat <br/> Clarkson",
      post: "Partner",
      company: "BankTech Ventures",
      linkedIn: "https://www.linkedin.com/in/nat-clarkson-b111025/",
      icon: "79",
    },
    {
      name: "Seyhun <br/> Hepdogan",
      post: "Director Model Risk Management",
      company: "Discover Financial Services",
      linkedIn: "https://www.linkedin.com/in/seyhun-h-7a82603/",
      icon: "78",
    },
    {
      name: "Shawn <br/> Ward",
      post: "Sr. Managing Director",
      company: "Jack Henry & Associates",
      linkedIn: "https://www.linkedin.com/in/shawnward/",
      icon: "77",
    },
    {
      name: "John <br/> Buhrmaster",
      post: "President and CEO",
      company: "1st National Bank of Scotia",
      linkedIn: "https://www.linkedin.com/in/john-buhrmaster-a0734491/",
      icon: "76",
    },
    {
      name: "Cyrus <br/> Taheri",
      post: "GLOBAL VP OF PARTNERSHIPS AND ECOSYSTEM",
      company: "FINASTRA UNIVERSAL BANKING",
      linkedIn: "https://www.linkedin.com/in/cyrus-taheri-2388164/",
      icon: "75",
    },
    {
      name: "Lee-Anne <br/> O'Selmo",
      post: "ССO",
      company: "Sagicor Bank",
      linkedIn: "https://www.linkedin.com/in/leeanne-oselmo",
      icon: "74",
    },
    {
      name: "Dean <br/> Jenkins",
      post: "VP Product Marketing",
      company: "Q2ebanking",
      linkedIn: "https://www.linkedin.com/in/dean-jenkins-4b69438/",
      icon: "73",
    },
    {
      name: "James <br/> Wrbanek",
      post: "Head of US Sales",
      company: "Wolters Kluwer",
      linkedIn: "https://www.linkedin.com/in/james-wrbanek-2722ba4/",
      icon: "71",
    },
    {
      name: "Charles <br/> Beyrouthy",
      post: "Managing Partner",
      company: "Forma Prime",
      linkedIn: "https://www.linkedin.com/in/charlesbeyrouthy/",
      icon: "69",
    },
    {
      name: "Hendrik <br/> Brackmann",
      post: "VP Data",
      company: "Tide",
      linkedIn: "https://www.linkedin.com/in/hendrik-brackmann-b2b5477a/",
      icon: "40",
    },
    {
      name: "Mo <br/> Zhai",
      post: "Director of Corporate Development and Strategic Partnerships",
      company: "OakNorth",
      icon: "65",
      linkedIn: "https://www.linkedin.com/in/mo-zhai-84644b12/",
    },
    {
      name: "Igor <br/> Mamut",
      post: "Division CFO",
      company: "Fiserv",
      icon: "64",
      linkedIn: "https://www.linkedin.com/in/igormamut/",
    },
    {
      name: "Lou Anne <br/> Alexander",
      post: "Member Board Of Directors",
      company: "equipifi",
      icon: "62",
      linkedIn: "https://www.linkedin.com/in/louannealexander/",
    },
    {
      name: "Joseph <br/> Scarlato",
      post: "CRO",
      company: "LOWERS RISK GROUP",
      icon: "60",
      linkedIn: "https://www.linkedin.com/in/joescarlato/",
    },
    {
      name: "Victor <br/> Alexiev",
      post: "Global Head of Venture Incubation",
      company: "Citi",
      icon: "57",
      linkedIn: "https://www.linkedin.com/in/victoralexiev/",
    },
    {
      name: "Russell <br/> Hornsby-Clifton",
      post: "CEO",
      company: "EFG Bank",
      icon: "56",
      linkedIn: "https://www.linkedin.com/in/russell-hornsby-clifton-49973a3/",
    },
    {
      name: "Dion <br/> Lisle",
      post: "Director of Corporate Ventures",
      company: "Huntington National Bank",
      icon: "54",
      linkedIn: "https://www.linkedin.com/in/dionlisle/",
    },
    {
      name: "Brad <br/> Schaefer",
      post: "VP of Product",
      company: "Abrigo",
      icon: "53",
      linkedIn: "https://www.linkedin.com/in/bradtschaefer/",
    },
    {
      name: "Lee <br/> Alexander",
      post: "Manager Product Management",
      company: "Abrigo",
      icon: "52",
      linkedIn: "https://www.linkedin.com/in/lee-alexander/",
    },
    {
      name: "Igor <br/> Ulis",
      post: "CDO",
      company: "Reach Financial",
      icon: "50",
      linkedIn: "https://www.linkedin.com/in/igorulis/",
    },
    {
      name: "Allan <br/> Rayson",
      post: "CIO",
      company: "Encore Bank",
      icon: "49",
      linkedIn: "https://www.linkedin.com/in/allanrayson/",
    },
    {
      name: "Bo <br/> Brustkern",
      post: "CEO",
      company: "Fintech Nexus",
      icon: "47",
      linkedIn: "https://www.linkedin.com/in/brustkern/",
    },
    {
      name: "Jimmy <br/> Yang",
      post: "CRO",
      company: "CIBC U.S",
      icon: "46",
      linkedIn: "https://www.linkedin.com/in/jy1234/",
    },
    {
      name: "Mayank <br/> Mishra",
      post: "Managing Director Platforms and Data",
      company: "Citi",
      icon: "45",
      linkedIn: "https://www.linkedin.com/in/mayank-mishra-digital/",
    },
    {
      name: "Bradley <br/> Schaufenbuel",
      post: "Vice President",
      company: "Paychex",
      icon: "1",
      linkedIn: "https://www.linkedin.com/in/bradleyschaufenbuel/",
    },
    {
      name: "Mike <br/> Kramer",
      post: "Chairman",
      company: "Southeastern Trust Company",
      icon: "2",
      linkedIn: "https://www.linkedin.com/in/mike-kramer-77234a2/",
    },
    {
      name: "Adi <br/> Engel",
      post: "CMO",
      company: "VCITA",
      icon: "3",
      linkedIn: "https://www.linkedin.com/in/adiengel/",
    },
    {
      name: "Rafi <br/> Majeed",
      post: "CDO",
      company: "BDO CANADA",
      icon: "4",
      linkedIn: "https://www.linkedin.com/in/digital-leader/  ",
    },
    {
      name: "Sandeep <br/> Kharidhi",
      post: "Chief Product Officer",
      company: "Risk Management Association",
      icon: "5",
      linkedIn: "https://www.linkedin.com/in/sandeepkharidhi/",
    },
    {
      name: "Mateusz <br/> Gintrowski",
      post: "CGO",
      company: "QUANTEE",
      icon: "6",
      linkedIn: "https://www.linkedin.com/in/mgintrowski/",
    },
    {
      name: "Anouk <br/> Robillard",
      post: "Ex-Corporate Strategy and Innovation at BDC",
      company: "now Angel Investor and advisor to startups",
      icon: "7",
      linkedIn: "https://www.linkedin.com/in/anouk-robillard-71a60112/",
    },
    {
      name: "Uma Sankar <br/> Kopalle",
      post: "PRODUCT DIRECTOR AND VP",
      company: "GLOBANT",
      icon: "8",
      linkedIn: "https://www.linkedin.com/in/umashankark/",
    },
    {
      name: "Jad Abou- <br/>Maarouf",
      post: "CDO",
      company: "FULTON BANK",
      icon: "9",
      linkedIn: "https://www.linkedin.com/in/jad-abou-maarouf/",
    },
    {
      name: "Jonathan <br/> Hodges",
      post: "CDO",
      company: "WORKIVA",
      icon: "11",
      linkedIn: "https://www.linkedin.com/in/jonathanhodges/",
    },
    {
      name: "Jayaprakash <br/> Bandu",
      post: "SVP SALES",
      company: "MPHASIS",
      icon: "13",
      linkedIn: "https://www.linkedin.com/in/jayaprakash-bandu/",
    },
    {
      name: "Paul <br/> Loberman",
      post: "CHIEF PRODUCT OFFICER",
      company: "UNTIED",
      icon: "14",
      linkedIn: "https://www.linkedin.com/in/paul-loberman/",
    },
    {
      name: "Aiaz <br/> Kazi",
      post: "EX. CHIEF PLATFORM OFFICER",
      company: "SANTANDER BANK",
      icon: "15",
      linkedIn: "https://www.linkedin.com/in/aiazkazi/",
    },
    {
      name: "Ron <br/> Stewart",
      post: "EVP BUSINESS DEVELOPMENT",
      company: "upSWOT",
      icon: "16",
      linkedIn: "https://www.linkedin.com/in/ron-stewart-0279a15/",
    },
    {
      name: "Klaus <br/> Kaasgaard",
      post: "EVP of Experience Design",
      company: "SAGE",
      icon: "17",
      linkedIn: "https://www.linkedin.com/in/klauskaasgaard/",
    },
    {
      name: "Jazz <br/> Samara",
      post: "Executive Strategy and Digital Innovation",
      company: "Wells Fargo",
      icon: "18",
      linkedIn: "https://www.linkedin.com/in/jazzsamra/",
    },
    {
      name: "Robert <br/> Kleiber",
      post: "CFO",
      company: "THE LCF GROUP",
      icon: "19",
      linkedIn: "https://www.linkedin.com/in/robert-kleiber-4034034/",
    },
    {
      name: "Dave <br/> Robertson",
      post: "HEAD OF TREASURY SOLUTIONS",
      company: "PMC TREASURY",
      icon: "20",
      linkedIn: "https://www.linkedin.com/in/daverobertson/",
    },
    {
      name: "Prasad <br/> T.Rao",
      post: "FORMER VP/GM",
      company: "MULESOFT",
      icon: "21",
      linkedIn: "https://www.linkedin.com/in/prasadtrao/",
    },
    {
      name: "Timothy <br/> Li",
      post: "HEAD OF TECHNOLOGY BUSINESS",
      company: "WARRIOR",
      icon: "22",
      linkedIn: "https://www.linkedin.com/in/timothytli/",
    },
    {
      name: "Robert J <br/> Bardusch",
      post: "FORMER COO",
      icon: "29",
      company: "VALLEY BANK",
      linkedIn: "https://www.linkedin.com/in/bob-bardusch-cio-coo/",
    },
    {
      name: "Madhusudan J <br/> Reddy",
      post: "EVP & CIO",
      company: "REPUBLICK BANK OF CHICAGO",
      icon: "30",
      linkedIn: "https://www.linkedin.com/in/madhu-reddy/",
    },
    {
      name: "Miguel <br/> Gamino",
      post: "CXO",
      company: "Simplicity",
      icon: "32",
      linkedIn: "https://www.linkedin.com/in/miguelgamino/",
    },
    {
      name: "Curt <br/> Raffi",
      post: "Chief Product & Innovation Officer",
      company: "Bottomline Technologies",
      icon: "34",
      linkedIn: "https://www.linkedin.com/in/curtraffi1/",
    },
    {
      name: "Scott <br/> Zimmer",
      post: "EX CHIEF INNOVATION OFFICER",
      company: "TRUIST",
      icon: "27",
      linkedIn: "https://www.linkedin.com/in/sczimmer/",
    },
    {
      name: "Garry <br/> Capers",
      post: "Division President",
      company: "Deluxe Corporation",
      icon: "33",
      linkedIn: "https://www.linkedin.com/in/garrycapersdeluxe/",
    },
    {
      name: "Mark <br/> Dreux",
      post: "Managing Director",
      company: "Active Digital",
      icon: "24",
      linkedIn: "https://www.linkedin.com/in/marksdreux/",
    },
    {
      name: "Ian <br/> Hurst",
      post: "OPERATING Advisor",
      company: "GTO PARTNERS",
      icon: "31",
      linkedIn: "https://www.linkedin.com/in/ian-hurst-ba143084/",
    },
  ];

  get speakers(): Array<Record<string, unknown>> {
    if (this.isFullSpeakerList) return this.speakerList;
    return this.speakerList.slice(0, 8);
  }

  fullSpeakerList(): void {
    this.isFullSpeakerList = true;
  }
}
