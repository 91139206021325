
import { Component, Vue, Prop } from "vue-property-decorator";
import BaseIcon from "@/components/UI/BaseIcon.vue";

@Component({
  components: { BaseIcon },
})
export default class SpeakerCard extends Vue {
  @Prop({
    default: {
      name: "name",
      post: "post",
      linkedIn: "linkedIn",
      email: "email",
      icon: "1",
      isTentative: false,
    },
  })
  speaker!: Record<string, string>;
  @Prop({ default: 1 }) id!: number;

  openWindow(link: string): void {
    window.open(link);
  }
}
