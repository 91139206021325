
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class BaseInput extends Vue {
  @Prop({ default: "" }) value!: string;
  @Prop({ default: "" }) placeholder!: string;
  @Prop({ default: "text" }) type!: string;
  @Prop({ default: false }) error!: boolean;

  onInput(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.$emit("input", target.value);
  }
}
