
import { Component, Vue } from "vue-property-decorator";
import BaseIcon from "@/components/UI/BaseIcon.vue";
import BaseButton from "@/components/UI/BaseButton.vue";

@Component({
  components: { BaseButton, BaseIcon },
})
export default class Hero extends Vue {
  get date(): number {
    const nowDate = new Date();
    const eventDate = new Date("October 7, 2022 15:00:00");
    return Number(eventDate) - Number(nowDate);
  }

  addToCalendar(): void {
    window.open(
      "https://www.google.com/calendar/render?action=TEMPLATE&text=upSWOT%20Strategic%20Session%20Next.WE&details=Next.We%20is%20about%20what%E2%80%99s%20NEXT%20-%20our%20future%20at%20upSWOT%2C%20and%20how%20WE%20can%20make%20it%20happen%20together.%20Through%20the%20period%20of%20our%20Strategic%20Session%2C%20we%20will%20jointly%20brainstorm%2C%20and%20strategize%20towards%20ensuring%20a%20prosperous%2C%20innovative%20and%20trailblazing%20for%20upSWOT.&location=NH%20Les%20Artes%20Hotel%20Valencia&dates=20221007T140000Z%2F20221010T110000Z&ctz=Europe%2FMadrid"
    );
  }

  openPdf(): void {
    window.open("upSWOT.pdf");
  }

  openMap(): void {
    window.open("https://maps.app.goo.gl/uBQG2nsXznfY2e8E9?g_st=ic");
  }
}
