var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"agenda"},[_c('div',{staticClass:"agenda__wrapper"},[_c('div',{staticClass:"agenda__title-and-nav"},[_c('img',{staticClass:"bg desktop-tablet",attrs:{"src":require("@/assets/agenda-bg.svg"),"alt":"agenda-bg"}}),_c('img',{staticClass:"bg mobile",attrs:{"src":require("@/assets/agenda-bg_mobile.svg"),"alt":"agenda-bg"}}),_c('h2',[_vm._v("Agenda")]),_c('div',{staticClass:"nav-arrows"},[_c('BaseIcon',{staticClass:"nav-arrows__left",attrs:{"size":"medium"}},[_c('i',{staticClass:"fa-solid fa-arrow-left-long",on:{"click":_vm.prevDay}})]),_c('BaseIcon',{staticClass:"nav-arrows__right",attrs:{"size":"medium"}},[_c('i',{staticClass:"fa-solid fa-arrow-right-long",on:{"click":_vm.nextDay}})])],1)]),_c('div',{staticClass:"agenda__box"},[_c('div',{staticClass:"date"},_vm._l((_vm.agenda),function(item,index){return _c('div',{key:index,class:['date__item', { chosenDay: _vm.chosenDay === index }],on:{"click":function($event){return _vm.chooseDay(index)}}},[_c('h4',[_vm._v(_vm._s(item.day))]),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(item.date))])])}),0),_c('div',{staticClass:"event"},_vm._l((_vm.agenda[_vm.chosenDay].schedule),function(item,index){return _c('div',{key:index,class:[
            'event__item',
            {
              workshops: _vm.isWorkshopStyle(item),
              conference: _vm.isConferenceStyle(item),
            },
          ]},[_c('p',{staticClass:"event__item-time subtitle-2"},[_vm._v(_vm._s(item.time))]),_c('div',{class:['event__item-grid', `grid${item.events.length}`]},_vm._l((item.events),function(eventItem,index){return _c('div',{key:index,staticClass:"event__item__event-box"},[(eventItem.type)?_c('p',{staticClass:"event__item-type"},[_vm._v(" "+_vm._s(eventItem.type)+" ")]):_vm._e(),_c('p',{staticClass:"event__item-evet subtitle-2"},[_vm._v(_vm._s(eventItem.event))])])}),0)])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }