<template>
  <div class="products">
    <div class="products__wrapper">
      <img
        class="bg desktop-tablet"
        src="@/assets/products-bg.svg"
        alt="products-bg"
      />
      <img class="bg mobile" src="@/assets/visa-bg_mobile.svg" alt="visa-bg" />

      <h2>upSWOT products</h2>

      <div class="products__videos">
        <iframe
          class="iframe"
          width="100%"
          height="400"
          src="https://www.youtube.com/embed/GLtfGaL-gFQ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>

        <iframe
          class="iframe"
          width="100%"
          height="400"
          src="https://www.youtube.com/embed/dpdRcUgzrvU"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.products {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 0 16px;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    max-width: 1140px;
    padding: 0 16px;
    z-index: 2;
  }

  h2 {
    margin-bottom: 32px;
  }

  h2 {
    margin-bottom: 32px;
  }

  &__videos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
  }
}

.bg {
  top: -70px;
  left: -270px;
}

@media screen and (max-width: 956px) {
  .products {
    &__videos {
      display: flex;
      flex-direction: column;
      row-gap: 32px;
    }
  }
}

@media screen and (max-width: 620px) {
  .iframe {
    height: 250px;
  }

  .bg {
    top: unset;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>