
import { Component, Vue, PropSync, Watch } from "vue-property-decorator";
import Hero from "@/components/Hero.vue";
import Welcome from "@/components/Welcome.vue";
import Photos from "@/components/Photos.vue";
import LeadGeneration from "@/components/LeadGeneration.vue";
import Speakers from "@/components/speaker/Speakers.vue";
import About from "@/components/About.vue";
import Products from "@/components/Products.vue";
import Agenda from "@/components/Agenda.vue";
import Podcasts from "@/components/Podcasts.vue";
import Reviews from "@/components/review/Reviews.vue";
import ContactUs from "@/components/ContactUs.vue";

@Component({
  components: {
    Hero,
    Welcome,
    Photos,
    LeadGeneration,
    Speakers,
    About,
    Products,
    Agenda,
    Reviews,
    Podcasts,
    ContactUs,
  },
})
export default class HomePage extends Vue {
  @PropSync("scrollToRef", { type: String }) syncedScrollToRef!: string;

  $refs!: {
    welcome: Vue;
    speakers: Vue;
    visa: Vue;
    [key: string]: Vue;
  };

  @Watch("scrollToRef")
  scrollTo(): void {
    this.$refs[this.syncedScrollToRef]?.$el.scrollIntoView({
      behavior: "smooth",
      block:
        this.syncedScrollToRef === "agenda" ||
        this.syncedScrollToRef === "speakers"
          ? "start"
          : "center",
    });
    this.syncedScrollToRef = "";
  }
}
