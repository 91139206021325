
import { Component, Vue } from "vue-property-decorator";
import BaseIcon from "@/components/UI/BaseIcon.vue";

@Component({
  components: { BaseIcon },
})
export default class ContactUs extends Vue {
  mailTo(mail: string): void {
    window.open(
      `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${mail}&tf=1`
    );
  }
}
