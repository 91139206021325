
import { Component, Vue } from "vue-property-decorator";
import BaseIcon from "@/components/UI/BaseIcon.vue";

@Component({
  components: { BaseIcon },
})
export default class Photos extends Vue {
  currentSlide = 0;
  photos = 16;

  get isPaginationEnabled(): boolean {
    return window.innerWidth < 621;
  }

  prevSlide(): void {
    if (this.currentSlide !== 0) this.currentSlide -= 1;
  }
  nextSlide(): void {
    if (this.currentSlide !== this.photos - 1) this.currentSlide += 1;
  }
  changeSlide(index: number): void {
    this.currentSlide = index;
  }
}
