
import { Component, Vue } from "vue-property-decorator";
import BaseButton from "@/components/UI/BaseButton.vue";

@Component({
  components: { BaseButton },
})
export default class Visa extends Vue {
  openPdf(): void {
    window.open("upSWOT.pdf");
  }
}
