
import { Component, Vue } from "vue-property-decorator";
import BaseButton from "@/components/UI/BaseButton.vue";
import BaseIcon from "@/components/UI/BaseIcon.vue";

@Component({
  components: { BaseButton, BaseIcon },
})
export default class Agenda extends Vue {
  agenda = [
    {
      day: "Day 1",
      date: "Friday, October 7",

      schedule: [
        {
          time: "3:00 - 5:00 pm",
          events: [
            {
              event: "Check-in / Hotel NH Valencia Las Artes",
            },
          ],
        },
        {
          time: "5:00 - 5:30 pm",
          events: [
            {
              event: "Welcome coffee / Registration",
            },
          ],
        },
        {
          time: "5:30 - 6:00 pm",
          events: [
            {
              type: "Presentation",
              event:
                "Presentations from Dmitry Norenko CEO, Dmitry Syvolap CCO, Andrew Davydchuk CTO",
            },
          ],
        },
        {
          time: "6:00 - 7:30 pm",
          events: [
            {
              event: "Workshops",
            },
          ],
        },
        {
          time: "7:30 - 10:30 pm",
          events: [
            {
              event: "Dinner",
            },
          ],
        },
      ],
    },
    {
      day: "Day 2",
      date: "Saturday, October 8",

      schedule: [
        {
          time: "9:00 - 9:30 am",
          events: [
            {
              event: "Welcome coffee",
            },
          ],
        },
        {
          time: "9:30 - 10:00 am",
          events: [
            {
              type: "Presentation",
              event: "upSWOT product demo",
            },
          ],
        },
        {
          time: "10:00 - 10:15 am",
          events: [
            {
              type: "Presentation",
              event: "upSWOT brand: how we feel, think & act",
            },
          ],
        },
        {
          time: "10:15 - 10:55 am",
          events: [
            {
              type: "Panel",
              event:
                "How do fintech solutions and neobanking help banks better serve businesses?",
            },
            {
              type: "Session",
              event: "Enterprise sales",
            },
          ],
        },
        {
          time: "10:55 - 11:35 am",
          events: [
            {
              type: "Panel",
              event:
                "Data monetization in financial services - what is the industry's future?",
            },
            {
              type: "Session",
              event: "Data Mining",
            },
          ],
        },
        {
          time: "11:35  - 12:15 pm",
          events: [
            {
              type: "Presentation",
              event: "Product/Design Process/Feature Roadmap/R&D",
            },
          ],
        },
        {
          time: "12:15  - 12:55 pm",
          events: [
            {
              type: "Panel",
              event: "How can fintechs grow bigger and faster?",
            },
            {
              type: "Session",
              event:
                "Business Lending and BNPL Platform - Technology and Data Access",
            },
          ],
        },
        {
          time: "12:55 - 2:05 pm",
          events: [
            {
              event: "Lunch",
            },
          ],
        },
        {
          time: "2:05 - 2:45 pm",
          events: [
            {
              type: "Presentation",
              event: "Presentation from Partner",
            },
          ],
        },
        {
          time: "2:45 - 3:25 pm",
          events: [
            {
              type: "Panel",
              event:
                '"Digital only" banking - how does this affect businesses?',
            },
            {
              type: "Session",
              event: "Building the startup created by the largest banks",
            },
          ],
        },
        {
          time: "3:25 - 4:05 pm",
          events: [
            {
              type: "Panel",
              event:
                "How will fintech change bank data and underwriting with cash flows?",
            },
            {
              type: "Session",
              event: "SMBs in India",
            },
          ],
        },
        {
          time: "4:05 - 4:30 pm",
          events: [
            {
              event: "Coffee Break & Networking",
            },
          ],
        },
        {
          time: "4:30 - 5:10 pm",
          events: [
            {
              type: "Panel",
              event:
                "What does the journey from startup to unicorn in fintech look like?",
            },
          ],
        },
        {
          time: "5:10 - 5:30 pm",
          events: [
            {
              type: "Presentation",
              event: "Front-End processes, Back-end process & DevOps at upSWOT",
            },
          ],
        },
        {
          time: "5:40 - 7:10 pm",
          events: [
            {
              event: "Workshops",
            },
          ],
        },
        {
          time: "7:10 - 8:00 pm",
          events: [
            {
              event: "Free time",
            },
          ],
        },
        {
          time: "8:30 - 12:30 am",
          events: [
            {
              event: "Evening event",
            },
          ],
        },
      ],
    },
    {
      day: "Day 3",
      date: "Sunday, October 9",

      schedule: [
        {
          time: "9:00 - 9:30 am",
          events: [
            {
              event: "Welcome coffee",
            },
          ],
        },
        {
          time: "9:30 - 9:50 am",
          events: [
            {
              type: "Presentation",
              event: "How to attract investment for growing a startup?",
            },
          ],
        },
        {
          time: "10:00 - 11:30 am",
          events: [
            {
              event: "Workshops",
            },
          ],
        },
        {
          time: "11:40 - 12:20 pm",
          events: [
            {
              type: "Panel",
              event: "Innovation: How can we balance it against risk?",
            },
            {
              type: "Session",
              event: "Huge banks working with Fintechs - how to make it work?",
            },
          ],
        },
        {
          time: "12:20  - 1:00 pm",
          events: [
            {
              type: "Panel",
              event:
                "What is the truth about working with banks from the fintech perspective?",
            },
            {
              type: "Session",
              event: "FDX",
            },
          ],
        },
        {
          time: "1:00 - 1:40 pm",
          events: [
            {
              type: "Panel",
              event: "How can regulation be a competitive asset in fintech?",
            },
            {
              type: "Session",
              event:
                "Demo of Abrigo's loan origination solution for small business loans.",
            },
          ],
        },
        {
          time: "1:40 - 2:40 pm",
          events: [
            {
              event: "Lunch",
            },
          ],
        },
        {
          time: "2:40 - 3:00 pm",
          events: [
            {
              type: "Presentation",
              event: "Business Analysis at upSWOT",
            },
          ],
        },
        {
          time: "3:00 - 3:40 pm",
          events: [
            {
              type: "Panel",
              event:
                "Modern Banking - what advancements should be developed in the digital age?",
            },
            {
              type: "Session",
              event: "Is it worth selling a company to a Core provider?",
            },
          ],
        },
        {
          time: "3:40 - 4:20 pm",
          events: [
            {
              type: "Panel",
              event:
                "What does the coming boom in B2B eCommerce say about the future of fintech?",
            },
            {
              type: "Session",
              event: "Spatial Finance",
            },
          ],
        },
        {
          time: "4:20 - 4:45 pm",
          events: [
            {
              event: "Coffee Break & Networking",
            },
          ],
        },
        {
          time: "4:45 - 5:25 pm",
          events: [
            {
              type: "Panel",
              event:
                "Is there any interaction between governments and fintechs?",
            },
          ],
        },
        {
          time: "5:25 - 6:05 pm",
          events: [
            {
              type: "Panel",
              event:
                "Broad-based credit scores and custom lender models: what are the roles of fintechs and credit bureaus in this ecosystem?",
            },
          ],
        },
        {
          time: "6:05 - 6:45 pm",
          events: [
            {
              type: "Panel",
              event:
                "How will fintechs increase the value of credit card businesses?",
            },
          ],
        },
        {
          time: "6:45 - 7:45 pm",
          events: [
            {
              event: "Free time",
            },
          ],
        },
        {
          time: "8:00 - 12:00 am",
          events: [
            {
              event: "Dinner",
            },
          ],
        },
      ],
    },
    {
      day: "Day 4",
      date: "Monday, October 10",

      schedule: [
        {
          time: "10:00 - 10:20 am",
          events: [
            {
              event: "Welcome coffee",
            },
          ],
        },
        {
          time: "10:20 - 10:30 am",
          events: [
            {
              event: "Workshop summary",
            },
          ],
        },
        {
          time: "10:30 - 10:40 am",
          events: [
            {
              type: "Panel",
              event: "Closing remarks",
            },
          ],
        },
        {
          time: "10:40 - 10:50 am",
          events: [
            {
              event: "Final photo",
            },
          ],
        },
        {
          time: "10:50 - 11:20 am",
          events: [
            {
              event: "Coffee break",
            },
          ],
        },
        {
          time: "11:20  - 12:00 pm",
          events: [
            {
              event: "Check out",
            },
          ],
        },
      ],
    },
  ];

  chosenDay = 0;

  isWorkshopStyle(item: any): boolean {
    const filtredItem = item.events.filter((item: Record<string, any>) =>
      item.event.includes("Workshop")
    );
    return filtredItem.length > 0;
  }

  isConferenceStyle(item: any): boolean {
    const filtredItem = item.events.filter(
      (item: Record<string, unknown>) =>
        item.type === "Panel" ||
        item.type === "Session" ||
        item.type === "Presentation"
    );
    return filtredItem.length > 0;
  }

  chooseDay(index: number): void {
    this.chosenDay = index;
  }

  prevDay(): void {
    if (this.chosenDay === 0) {
      this.chosenDay = 3;
    } else this.chosenDay -= 1;
  }

  nextDay(): void {
    if (this.chosenDay === 3) {
      this.chosenDay = 0;
    } else this.chosenDay += 1;
  }
}
