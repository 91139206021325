
import { Vue, Component } from "vue-property-decorator";
import BaseInput from "@/components/UI/BaseInput.vue";
import BaseButton from "@/components/UI/BaseButton.vue";

@Component({
  components: { BaseInput, BaseButton },
})
export default class LeadGeneration extends Vue {
  isFormSent = false;
  name = "";
  organization = "";
  position = "";
  email = "";
  linkedin = "";
  errors = {
    name: false,
    organization: false,
    position: false,
    email: false,
    linkedin: false,
  };

  checkFields(): boolean {
    this.errors.name = this.name.length < 3;
    this.errors.organization = this.organization.length < 3;
    this.errors.position = this.position.length < 3;
    this.errors.linkedin = this.linkedin.length < 3;
    // eslint-disable-next-line
    this.errors.email = !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      this.email
    );

    return (
      !this.errors.name &&
      !this.errors.organization &&
      !this.errors.position &&
      !this.errors.email &&
      !this.errors.linkedin
    );
  }
  async sendForm(): Promise<void> {
    if (!this.checkFields()) return;
    try {
      await fetch("https://next.upswot.com/api/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullName: this.name,
          organization: this.organization,
          position: this.position,
          email: this.email,
          linkedIn: this.linkedin,
        }),
      });
      this.isFormSent = true;
    } catch (error: any) {
      window.alert("Something wrong...");
    }
  }
}
