
import { Component, Vue } from "vue-property-decorator";
import ReviewerCard from "@/components/review/ReviewerCard.vue";
import BaseIcon from "@/components/UI/BaseIcon.vue";

@Component({
  components: { ReviewerCard, BaseIcon },
})
export default class Reviews extends Vue {
  reviews = [
    {
      name: "Prasad T. Rao",
      post: "FORMER VP/GM",
      company: "MULESOFT",
      quote:
        "Why am I excited about upSWOT’s future? Every successful company needs a level playing field where there is transparency and access to financial markets and customer opportunities. upSWOT essentially is designed to become “the business platform” of the future where every and all business transactions can be handled with ease. This has the potential to eclipse the likes of Salesforce and Microsoft.",
    },
    {
      name: "Mateusz Gintrowski",
      post: "CHIEF GROWTH OFFICER",
      company: "QUANTEE",
      quote:
        "I have no doubt upSWOT is at the right path to becoming an unicorn. With such a motivated team, visionary founder and strong value proposition upSWOT has everything it takes to go public one day at multibillion valuation.",
    },
    {
      name: "Curt Raffi",
      post: "CHIEF PRODUCT & INNOVATION OFFICER",
      company: "BOTTOMLINE TECHNOLOGIES",
      quote:
        "Banks need to stay relevant to the small business owner of the future. They want to understand what the small business does on a daily basis, while also offering contextual insights and guidance. However, banks also need a flexible front end that can easily consume APIs and third-party services without a big development effort. upSWOT makes that connectivity drop-dead simple, while also providing data driven insights and messaging in a way that banks typically have only imagined.",
    },
  ];

  currentSlide = 0;

  prevSlide(): void {
    if (this.currentSlide !== 0) this.currentSlide -= 1;
  }

  nextSlide(): void {
    if (this.currentSlide !== this.reviews.length - 1) this.currentSlide += 1;
  }

  changeSlide(index: number): void {
    this.currentSlide = index;
  }
}
